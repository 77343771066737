import { ProductLabels, TProductLabels } from 'components/ProductLabels';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { Accordion } from '../Accordion';
import { Text as Paragraph, Title } from '../Typography';
import {
  ColData,
  ColHeading,
  ColHeadList,
  Container,
  CustomTab,
  Disclaimer,
  Heading,
  Label,
  MainTitle,
  MobileLabel,
  Note,
  SubTitle,
  Table,
  TableContainer,
  Td,
  Th,
  Tr,
} from './styles';

interface IProductNutrition {
  superTitle: string;
  mainHeading: string;
  title: string;
  ingredientsTitle: string;
  productLabelsTitle: string;
  descriptionTitle: string;
  note: string;
  disclaimer: string;
  servingReferenceQuantityText: string;
  servingReferenceQuantity: number;
  referenceQuantity: number;
  servingDescriptionText: string;
  servingDescription: string;
  colEmpty?: boolean[];
  decimalSeparator: string;
  headings?: {
    unPreparedPerMeasure: string;
    unPreparedPerServing: string;
    preparedPerMeasure: string;
    preparedPerServing: string;
    intakePercentage: string;
  };
  colCheck?: {
    preparedPerMeasureAllEmpty: string;
    preparedPerServingAllEmpty: string;
    preparedIntakePercentageAllEmpty: string;
    unPreparedPerMeasureAllEmpty: string;
    unPreparedPerServingAllEmpty: string;
    unPreparedIntakePercentageAllEmpty: string;
  };
  body?: {
    energy?: {
      name: string;
      prepared: IEnergy;
      unPrepared: IEnergy;
    };
    nutrients?: INutrient[];
  };
}
interface IEnergy {
  kiloJoules?: INutrition;
  kiloCalories?: INutrition;
}
interface INutrient {
  id?: string;
  name: string;
  sortOrder?: number;
  prepared?: INutrition;
  unPrepared?: INutrition;
}

interface INutrition {
  name: string;
  perMeasure: number;
  perMeasureUom: string;
  perServing: number;
  perServingUom: string;
  intakePercentage: number;
}

export type { INutrient, INutrition, IProductNutrition };

export const ProductNutrition = ({
  id,
  productDescription,
  productDescriptionTitle,
  ingredients,
  nutritionData,
  certficationTitle = '',
  trackingEvent,
  productLabels,
}: {
  id: string;
  ingredients: string;
  productDescription: string;
  productDescriptionTitle?: string;
  nutritionData: IProductNutrition;
  certficationTitle?: string;
  trackingEvent?: (title: string, position: number) => void;
  productLabels?: TProductLabels;
}) => {
  const componentName = 'product-nutrition';
  const name = 'ProductNutrition';
  const isDesktop = useMediaQuery('(min-width: 500px)', {
    defaultValue: false,
    initializeWithValue: false,
  });

  const decimalClean = (value: number) =>
    String(value).replace('.', nutritionData.decimalSeparator);

  const getNutritionData = (data: IProductNutrition) => {
    const title = (
      <Title data-test={`${id}-${componentName}-title-desktop`} tag="div" type="sm">
        {data.title}
      </Title>
    );

    const body = (
      <div
        data-test={`${id}-${componentName}-body-desktop`}
        itemProp="nutrition"
        itemScope
        itemType="https://schema.org/NutritionInformation"
      >
        {data?.note && (
          <>
            <br />
            <Note tag="div" type="lead">
              {data.note}
            </Note>
          </>
        )}
        {data?.servingDescription && (
          <Note tag="div" type="lead">
            {data?.servingDescription}
          </Note>
        )}
        <TableContainer>
          <Table>
            <tbody>
              <Tr>
                <Th />
                {!data?.colCheck?.unPreparedPerMeasureAllEmpty && (
                  <Th>
                    <Heading tag="div" type="lead">
                      {data?.headings?.unPreparedPerMeasure}
                    </Heading>{' '}
                  </Th>
                )}
                {!data?.colCheck?.unPreparedPerServingAllEmpty && (
                  <Th>
                    <Heading tag="div" type="lead">
                      {data?.headings?.unPreparedPerServing}
                    </Heading>
                  </Th>
                )}
                {!data?.colCheck?.unPreparedIntakePercentageAllEmpty && (
                  <Th>
                    <Heading tag="div" type="lead">
                      {data?.headings?.intakePercentage}
                    </Heading>
                  </Th>
                )}
                {!data?.colCheck?.preparedPerMeasureAllEmpty && (
                  <Th>
                    <Heading tag="div" type="lead">
                      {data?.headings?.preparedPerMeasure}
                    </Heading>
                  </Th>
                )}
                {!data?.colCheck?.preparedPerServingAllEmpty && (
                  <Th>
                    <Heading tag="div" type="lead">
                      {data?.headings?.preparedPerServing}
                    </Heading>
                  </Th>
                )}
                {!data?.colCheck?.preparedIntakePercentageAllEmpty && (
                  <Th>
                    <Heading tag="div" type="lead">
                      {data?.headings?.intakePercentage}
                    </Heading>
                  </Th>
                )}
              </Tr>
              {data?.body?.energy && (
                <Tr line="odd">
                  <Td>
                    <Label tag="div" type="lg">
                      {data?.body?.energy?.name && data?.body?.energy?.name}
                    </Label>
                  </Td>
                  {!data?.colCheck?.unPreparedPerMeasureAllEmpty && (
                    <Td>
                      <Paragraph tag="div" type="lg">
                        {data?.body?.energy?.unPrepared?.kiloJoules?.perMeasure && (
                          <>
                            {`${decimalClean(data?.body?.energy?.unPrepared?.kiloJoules?.perMeasure)}
                            ${data?.body?.energy?.unPrepared?.kiloJoules?.name || 'kj'}`}
                            <br />
                          </>
                        )}
                        {data?.body?.energy?.unPrepared?.kiloCalories?.perMeasure &&
                          `${decimalClean(data?.body?.energy?.unPrepared?.kiloCalories?.perMeasure)}
                            ${data?.body?.energy?.unPrepared?.kiloCalories?.name || 'kcal'}`}
                      </Paragraph>
                    </Td>
                  )}
                  {!data?.colCheck?.unPreparedPerServingAllEmpty && (
                    <Td>
                      <Paragraph tag="div" type="lg">
                        {data?.body?.energy?.unPrepared?.kiloJoules?.perServing && (
                          <>
                            {`${decimalClean(data?.body?.energy?.unPrepared?.kiloJoules?.perServing)}
                            ${data?.body?.energy?.unPrepared?.kiloJoules?.name || 'kj'}`}
                            <br />
                          </>
                        )}
                        {data?.body?.energy?.unPrepared?.kiloCalories?.perServing &&
                          `${decimalClean(data?.body?.energy?.unPrepared?.kiloCalories?.perServing)}
                            ${data?.body?.energy?.unPrepared?.kiloCalories?.name || 'kcal'}`}
                      </Paragraph>
                    </Td>
                  )}
                  {!data?.colCheck?.unPreparedIntakePercentageAllEmpty && (
                    <Td>
                      {data?.body?.energy?.unPrepared?.kiloJoules?.intakePercentage ? (
                        <Paragraph tag="div" type="lg">
                          {`${decimalClean(data?.body?.energy?.unPrepared?.kiloJoules?.intakePercentage)} %`}
                        </Paragraph>
                      ) : (
                        data?.body?.energy?.unPrepared?.kiloCalories?.intakePercentage && (
                          <Paragraph tag="div" type="lg">
                            {`${decimalClean(data?.body?.energy?.unPrepared?.kiloCalories?.intakePercentage)} %`}
                          </Paragraph>
                        )
                      )}
                    </Td>
                  )}
                  {!data?.colCheck?.preparedPerMeasureAllEmpty && (
                    <Td>
                      <Paragraph tag="div" type="lg">
                        {data?.body?.energy?.prepared?.kiloJoules?.perMeasure && (
                          <>
                            {`${decimalClean(data?.body?.energy?.prepared?.kiloJoules?.perMeasure)}
                            ${data?.body?.energy?.prepared?.kiloJoules?.name || 'kj'}`}
                            <br />
                          </>
                        )}
                        {data?.body?.energy?.prepared?.kiloCalories?.perMeasure &&
                          `${decimalClean(data?.body?.energy?.prepared?.kiloCalories?.perMeasure || 0)}
                            ${data?.body?.energy?.prepared?.kiloCalories?.name || 'kcal'}`}
                      </Paragraph>
                    </Td>
                  )}
                  {!data?.colCheck?.preparedPerServingAllEmpty && (
                    <Td>
                      <Paragraph tag="div" type="lg">
                        {data?.body?.energy?.prepared?.kiloJoules?.perServing && (
                          <>
                            {`${decimalClean(data?.body?.energy?.prepared?.kiloJoules?.perServing)}
                            ${data?.body?.energy?.prepared?.kiloJoules?.name || 'kj'}`}
                            <br />
                          </>
                        )}
                        {data?.body?.energy?.prepared?.kiloCalories?.perServing &&
                          `${decimalClean(data?.body?.energy?.prepared?.kiloCalories?.perServing)}
                            ${data?.body?.energy?.prepared?.kiloCalories?.name || 'kcal'}`}
                      </Paragraph>
                    </Td>
                  )}
                  {!data?.colCheck?.preparedIntakePercentageAllEmpty && (
                    <Td>
                      {data?.body?.energy?.prepared?.kiloJoules?.intakePercentage ? (
                        <Paragraph tag="div" type="lg">
                          {`${decimalClean(data?.body?.energy?.prepared?.kiloJoules?.intakePercentage)} %`}
                        </Paragraph>
                      ) : (
                        data?.body?.energy?.prepared?.kiloCalories?.intakePercentage && (
                          <Paragraph tag="div" type="lg">
                            {`${decimalClean(data?.body?.energy?.prepared?.kiloCalories?.intakePercentage)} %`}
                          </Paragraph>
                        )
                      )}
                    </Td>
                  )}
                </Tr>
              )}
              {data?.body?.nutrients
                ?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
                .map((item: INutrient, index: number) => (
                  <Tr
                    line={index % 2 === 0 ? 'even' : 'odd'}
                    key={`nutrition-tr-${item.id ?? index}`}
                  >
                    <Td>
                      <Label tag="div" type="lg">
                        {item?.name && item?.name}
                      </Label>
                    </Td>
                    {!data?.colCheck?.unPreparedPerMeasureAllEmpty && (
                      <Td>
                        <Paragraph tag="div" type="lg">
                          {item?.unPrepared?.perMeasure
                            ? `
                      ${decimalClean(item?.unPrepared?.perMeasure)} ${
                        item?.unPrepared?.perMeasureUom
                      }`
                            : '0 g'}
                        </Paragraph>
                      </Td>
                    )}
                    {!data?.colCheck?.unPreparedPerServingAllEmpty && (
                      <Td>
                        <Paragraph tag="div" type="lg">
                          {item?.unPrepared?.perServing
                            ? `
                      ${decimalClean(item?.unPrepared?.perServing)} ${
                        item?.unPrepared?.perServingUom
                      }`
                            : '0 g'}
                        </Paragraph>
                      </Td>
                    )}
                    {!data?.colCheck?.unPreparedIntakePercentageAllEmpty && (
                      <Td>
                        <Paragraph tag="div" type="lg">
                          {item?.unPrepared?.intakePercentage
                            ? `
                      ${decimalClean(item?.unPrepared?.intakePercentage)} %`
                            : item?.id === 'FIBTG'
                              ? ''
                              : '0 %'}
                        </Paragraph>
                      </Td>
                    )}
                    {!data?.colCheck?.preparedPerMeasureAllEmpty && (
                      <Td>
                        <Paragraph tag="div" type="lg">
                          {item?.prepared?.perMeasure
                            ? `
                      ${decimalClean(item?.prepared?.perMeasure)} ${item?.prepared?.perMeasureUom}`
                            : '0 g'}
                        </Paragraph>
                      </Td>
                    )}
                    {!data?.colCheck?.preparedPerServingAllEmpty && (
                      <Td>
                        <Paragraph tag="div" type="lg">
                          {item?.prepared?.perServing
                            ? `
                      ${decimalClean(item?.prepared?.perServing)} ${item?.prepared?.perServingUom}`
                            : '0 g'}
                        </Paragraph>
                      </Td>
                    )}
                    {!data?.colCheck?.preparedIntakePercentageAllEmpty && (
                      <Td>
                        <Paragraph tag="div" type="lg">
                          {item?.prepared?.intakePercentage
                            ? `
                      ${decimalClean(item?.prepared?.intakePercentage)} %`
                            : item?.id === 'FIBTG'
                              ? ''
                              : '0 %'}
                        </Paragraph>
                      </Td>
                    )}
                  </Tr>
                ))}
            </tbody>
          </Table>
        </TableContainer>
        {data?.disclaimer && (
          <Disclaimer tag="p" type="sm">
            {data?.disclaimer}
          </Disclaimer>
        )}
      </div>
    );
    const stringTitle = data.title;
    return { title, body, stringTitle };
  };

  const getNutritionDataMobile = (data: IProductNutrition) => {
    const title = (
      <Title data-test={`${id}-${componentName}-title-desktop`} tag="div" type="xss">
        {data.title}
      </Title>
    );

    const body = (
      <div
        data-test={`${id}-${componentName}-body-desktop`}
        itemProp="nutrition"
        itemScope
        itemType="https://schema.org/NutritionInformation"
      >
        {data?.note && (
          <Note tag="div" type="lead">
            {data.note}
          </Note>
        )}
        <br />
        {data?.servingReferenceQuantityText && (
          <Note tag="div" type="lead">
            {data?.servingReferenceQuantityText} {data?.servingReferenceQuantity}g
          </Note>
        )}
        <CustomTab>
          <Tabs>
            {/* Headings */}
            <div className="w-full overflow-y-auto top-headerM bg-white">
              <TabList>
                {!data?.colCheck?.unPreparedPerMeasureAllEmpty && (
                  <Tab>
                    <Heading tag="div" type="sm">
                      {data?.headings?.unPreparedPerMeasure}
                    </Heading>
                  </Tab>
                )}
                {!data?.colCheck?.unPreparedPerServingAllEmpty && (
                  <Tab>
                    <Heading tag="div" type="sm">
                      {data?.headings?.unPreparedPerServing}
                    </Heading>
                  </Tab>
                )}
                {!data?.colCheck?.unPreparedIntakePercentageAllEmpty && (
                  <Tab>
                    <Heading tag="div" type="sm">
                      {data?.headings?.intakePercentage}
                    </Heading>
                  </Tab>
                )}
                {!data?.colCheck?.preparedPerMeasureAllEmpty && (
                  <Tab>
                    <Heading tag="div" type="sm">
                      {data?.headings?.preparedPerMeasure}
                    </Heading>
                  </Tab>
                )}
                {!data?.colCheck?.preparedPerServingAllEmpty && (
                  <Tab>
                    <Heading tag="div" type="sm">
                      {data?.headings?.preparedPerServing}
                    </Heading>
                  </Tab>
                )}
                {!data?.colCheck?.preparedIntakePercentageAllEmpty && (
                  <Tab>
                    <Heading tag="div" type="sm">
                      {data?.headings?.intakePercentage}
                    </Heading>
                  </Tab>
                )}
              </TabList>
            </div>
            {/* left hand side labels */}
            <ColHeading className="w-1/2 inline-block z-negative relative">
              <ColHeadList $line="odd" className="h-90">
                <MobileLabel tag="div" type="md">
                  {data?.body?.energy?.name && data?.body?.energy?.name}
                </MobileLabel>
              </ColHeadList>
              {data?.body?.nutrients
                ?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
                .map((item: INutrient, index: number) => (
                  <ColHeadList
                    $line={index % 2 === 0 ? 'even' : 'odd'}
                    key={`nutrition-head-${item.id ?? index}`}
                  >
                    <MobileLabel tag="div" type="md">
                      {item?.name && item?.name}
                    </MobileLabel>
                  </ColHeadList>
                ))}
            </ColHeading>
            {/* data 1 */}
            {!data?.colCheck?.unPreparedPerMeasureAllEmpty && (
              <TabPanel className="w-1/2 hidden z-negative relative">
                <>
                  <ColData className="h-90" $line="odd">
                    <Paragraph tag="div" type="lg">
                      {data?.body?.energy?.unPrepared?.kiloJoules?.perMeasure &&
                        `${decimalClean(data?.body?.energy?.unPrepared?.kiloJoules?.perMeasure)}
                      ${data?.body?.energy?.unPrepared?.kiloJoules?.name}`}
                      {data?.body?.energy?.unPrepared?.kiloJoules?.perMeasure && <br />}
                      {data?.body?.energy?.unPrepared?.kiloCalories?.perMeasure &&
                        `${decimalClean(data?.body?.energy?.unPrepared?.kiloCalories?.perMeasure)}
                      ${data?.body?.energy?.unPrepared?.kiloCalories?.name}`}
                    </Paragraph>
                  </ColData>
                </>
                {data?.body?.nutrients?.map((item: INutrient, index: number) => (
                  <div key={`nutrition-col-1-${item.id ?? index}`}>
                    <ColData $line={index % 2 === 0 ? 'even' : 'odd'}>
                      <Paragraph tag="div" type="lg">
                        {item?.unPrepared?.perMeasure
                          ? `${decimalClean(item?.unPrepared?.perMeasure)} ${
                              item?.unPrepared?.perMeasureUom
                            }`
                          : '0 g'}
                      </Paragraph>
                    </ColData>
                  </div>
                ))}
              </TabPanel>
            )}
            {/* data 2 */}
            {!data?.colCheck?.unPreparedPerServingAllEmpty && (
              <TabPanel className="w-1/2 hidden z-negative relative">
                <>
                  <ColData className="h-90" $line="odd">
                    <Paragraph tag="div" type="lg">
                      {data?.body?.energy?.unPrepared?.kiloJoules?.perServing &&
                        `${decimalClean(data?.body?.energy?.unPrepared?.kiloJoules?.perServing)}
                      ${data?.body?.energy?.unPrepared?.kiloJoules?.name}`}
                      {data?.body?.energy?.unPrepared?.kiloJoules?.perServing && <br />}
                      {data?.body?.energy?.unPrepared?.kiloCalories?.perServing &&
                        `${decimalClean(data?.body?.energy?.unPrepared?.kiloCalories?.perServing)}
                      ${data?.body?.energy?.unPrepared?.kiloCalories?.name}`}
                    </Paragraph>
                  </ColData>
                </>
                {data?.body?.nutrients?.map((item: INutrient, index: number) => (
                  <div key={`nutrition-col-2-${item.id ?? index}`}>
                    <ColData $line={index % 2 === 0 ? 'even' : 'odd'}>
                      <Paragraph tag="div" type="lg">
                        {item?.unPrepared?.perServing
                          ? `${decimalClean(item?.unPrepared?.perServing)} g`
                          : '0 g'}
                      </Paragraph>
                    </ColData>
                  </div>
                ))}
              </TabPanel>
            )}
            {/* data 3 */}
            {!data?.colCheck?.unPreparedIntakePercentageAllEmpty && (
              <TabPanel className="w-1/2 hidden z-negative relative">
                <>
                  <ColData className="h-90" $line="odd">
                    <Paragraph tag="div" type="lg">
                      {data?.body?.energy?.unPrepared?.kiloJoules?.intakePercentage &&
                        `${decimalClean(
                          data?.body?.energy?.unPrepared?.kiloJoules?.intakePercentage,
                        )} %`}
                    </Paragraph>
                  </ColData>
                </>
                {data?.body?.nutrients?.map((item: INutrient, index: number) => (
                  <div key={`nutrition-col-3-${item.id ?? index}`}>
                    <ColData $line={index % 2 === 0 ? 'even' : 'odd'}>
                      <Paragraph tag="div" type="lg">
                        {item?.unPrepared?.intakePercentage
                          ? `${decimalClean(item?.unPrepared?.intakePercentage)} %`
                          : '0 %'}
                      </Paragraph>
                    </ColData>
                  </div>
                ))}
              </TabPanel>
            )}
            {/* data 4 */}
            {!data?.colCheck?.preparedPerMeasureAllEmpty && (
              <TabPanel className="w-1/2 hidden z-negative relative">
                <>
                  <ColData className="h-90" $line="odd">
                    <Paragraph tag="div" type="lg">
                      {data?.body?.energy?.prepared?.kiloJoules?.perMeasure &&
                        `${decimalClean(data?.body?.energy?.prepared?.kiloJoules?.perMeasure)}
                      ${data?.body?.energy?.prepared?.kiloJoules?.name}`}
                      {data?.body?.energy?.prepared?.kiloJoules?.perMeasure && <br />}
                      {data?.body?.energy?.prepared?.kiloCalories?.perMeasure &&
                        `${decimalClean(data?.body?.energy?.prepared?.kiloCalories?.perMeasure)}
                      ${data?.body?.energy?.prepared?.kiloCalories?.name}`}
                    </Paragraph>
                  </ColData>
                </>
                {data?.body?.nutrients?.map((item: INutrient, index: number) => (
                  <div key={`nutrition-col-4-${item.id ?? index}`}>
                    <ColData $line={index % 2 === 0 ? 'even' : 'odd'}>
                      <Paragraph tag="div" type="lg">
                        {item?.prepared?.perMeasure
                          ? `${decimalClean(item?.prepared?.perMeasure)} g`
                          : '0 g'}
                      </Paragraph>
                    </ColData>
                  </div>
                ))}
              </TabPanel>
            )}
            {/* data 5 */}
            {!data?.colCheck?.preparedPerServingAllEmpty && (
              <TabPanel className="w-1/2 hidden z-negative relative">
                <>
                  <ColData className="h-90" $line="odd">
                    <Paragraph tag="div" type="lg">
                      {data?.body?.energy?.prepared?.kiloJoules?.perServing &&
                        `${decimalClean(data?.body?.energy?.prepared?.kiloJoules?.perServing)}
                      ${data?.body?.energy?.prepared?.kiloJoules?.name}`}
                      {data?.body?.energy?.prepared?.kiloJoules?.perServing && <br />}
                      {data?.body?.energy?.prepared?.kiloCalories?.perServing &&
                        `${decimalClean(data?.body?.energy?.prepared?.kiloCalories?.perServing)}
                      ${data?.body?.energy?.prepared?.kiloCalories?.name}`}
                    </Paragraph>
                  </ColData>
                </>
                {data?.body?.nutrients?.map((item: INutrient, index: number) => (
                  <div key={`nutrition-col-5-${item.id ?? index}`}>
                    <ColData $line={index % 2 === 0 ? 'even' : 'odd'}>
                      <Paragraph tag="div" type="lg">
                        {item?.prepared?.perServing
                          ? `${decimalClean(item?.prepared?.perServing)} g`
                          : '0 g'}
                      </Paragraph>
                    </ColData>
                  </div>
                ))}
              </TabPanel>
            )}
            {/* data 6 */}
            {!data?.colCheck?.preparedIntakePercentageAllEmpty && (
              <TabPanel className="w-1/2 hidden z-negative relative">
                <>
                  <ColData className="h-90" $line="odd">
                    <Paragraph tag="div" type="lg">
                      {data?.body?.energy?.prepared?.kiloJoules?.intakePercentage &&
                        `${decimalClean(
                          data?.body?.energy?.prepared?.kiloJoules?.intakePercentage,
                        )} %`}
                    </Paragraph>
                  </ColData>
                </>
                {data?.body?.nutrients?.map((item: INutrient, index: number) => (
                  <div key={`nutrition-col-6-${item.id ?? index}`}>
                    <ColData $line={index % 2 === 0 ? 'even' : 'odd'}>
                      <Paragraph tag="div" type="lg">
                        {item?.prepared?.intakePercentage
                          ? `${decimalClean(item?.prepared?.intakePercentage)} %`
                          : '0 %'}
                      </Paragraph>
                    </ColData>
                  </div>
                ))}
              </TabPanel>
            )}
          </Tabs>
        </CustomTab>
        {data?.disclaimer && (
          <Disclaimer tag="p" type="sm">
            {data?.disclaimer}
          </Disclaimer>
        )}
      </div>
    );
    const stringTitle = data.title;
    return { title, body, stringTitle };
  };

  const getIngredientData = (ingredientsTitle: string) => {
    const title = (
      <div className="title-style-xxs mmd:title-style-sm">{ingredientsTitle || 'Ingredients'}</div>
    );
    const body = (
      <div data-test={`${id}-${componentName}-ingredients-body-desktop`} className="pb-5">
        {ingredients}
      </div>
    );
    const stringTitle = ingredientsTitle;
    return { title, body, stringTitle };
  };

  const getProductDescription = (descriptionTitle: string) => {
    const title = (
      <div className="title-style-xxs mmd:title-style-sm">{descriptionTitle || 'Description'}</div>
    );
    const body = (
      <div
        data-test={`${id}-${componentName}-product-description-body-desktop`}
        className="pb-5"
        dangerouslySetInnerHTML={{ __html: productDescription }}
      />
    );
    const stringTitle = descriptionTitle;
    return { title, body, stringTitle };
  };

  const getProductLabelAccordionData = () => {
    const title = (
      <div className="mmd:title-style-sm title-style-xxs" id="accordion-certifications">
        {nutritionData.productLabelsTitle}
      </div>
    );

    const body = productLabels?.length && (
      <ProductLabels labels={productLabels} showDescriptionText />
    );
    const stringTitle = certficationTitle;
    return { title, body, stringTitle };
  };

  let dataPresent = true;
  if (
    nutritionData?.colCheck?.preparedPerMeasureAllEmpty &&
    nutritionData?.colCheck?.preparedPerServingAllEmpty &&
    nutritionData?.colCheck?.preparedIntakePercentageAllEmpty &&
    nutritionData?.colCheck?.unPreparedPerMeasureAllEmpty &&
    nutritionData?.colCheck?.unPreparedPerServingAllEmpty &&
    nutritionData?.colCheck?.unPreparedIntakePercentageAllEmpty
  ) {
    dataPresent = false;
  }
  const desktopData = [];
  if (productDescription && productDescriptionTitle)
    desktopData.push(getProductDescription(productDescriptionTitle));
  if (ingredients) desktopData.push(getIngredientData(nutritionData?.ingredientsTitle));
  if (dataPresent && nutritionData) desktopData.push(getNutritionData(nutritionData));
  if (productLabels) desktopData.push(getProductLabelAccordionData());

  const mobileData = [];
  if (productDescription && productDescriptionTitle) {
    mobileData.push(getProductDescription(productDescriptionTitle));
  }
  if (ingredients) mobileData.push(getIngredientData(nutritionData?.ingredientsTitle));
  if (dataPresent && nutritionData) mobileData.push(getNutritionDataMobile(nutritionData));
  if (productLabels) mobileData.push(getProductLabelAccordionData());

  return (
    <Container data-test={`${id}-${componentName}-container`} data-print="hide">
      {nutritionData?.superTitle && (
        <SubTitle data-test={`${id}-${componentName}-subtitle`} tag="div" type="lead">
          {nutritionData?.superTitle}
        </SubTitle>
      )}
      {nutritionData?.mainHeading && (
        <MainTitle data-test={`${id}-${componentName}-maintitle`} tag="div" type="md">
          {nutritionData?.mainHeading}
        </MainTitle>
      )}

      <div className="sm:w-viewPortWidthMobile md:w-full">
        {(productDescription || ingredients || nutritionData || productLabels) && (
          <Accordion
            data-test={`${id}-${componentName}-product-nutrition-accordion-desktop`}
            name={name}
            data={isDesktop ? desktopData : mobileData}
            collapsed
            page="product"
            trackingEvent={trackingEvent && trackingEvent}
          />
        )}
      </div>
    </Container>
  );
};
