import styled from 'styled-components';
import { COLORS } from 'styles/constants';
import tw from 'tailwind-styled-components';
import { Text as Paragraph, Title } from '../Typography';

export const Container = tw.div`
  w-full
  mt-10
  mb-5
  md:mt-20
`;
Container.displayName = 'Container';

export const TableContainer = tw.div`
  mt-8
`;
TableContainer.displayName = 'TableContainer';

export const Table = tw.table`
  w-full
  table-fixed
`;
Table.displayName = 'Table';

export const Tr = tw.tr`
  ${(p: { line?: string }) => (p.line === 'odd' ? 'bg-lightBrown' : '')}
`;
Tr.displayName = 'Row';

export const Th = tw.th`
  text-left
  px-2.5
  md:px-5
  pb-2.5
  align-top
`;
Th.displayName = 'TableHeader';

export const Td = tw.td`
  h-16
  px-2.5
  md:px-5
  md:h-20
`;
Td.displayName = 'Cell';

export const CustomTab = styled.div`
  margin-top: 20px;
  .react-tabs__tab-panel--selected {
    display: inline-block;
  }
  .react-tabs__tab {
    border: 0;
    border-bottom: 3px solid #d4ded3;
    margin: 0;
    padding: 10px 15px;
    white-space: nowrap;
    color: ${COLORS.primary};
    :after {
      width: 4px;
      height: 4px;
      content: '';
      border-radius: 2px;
      background-color: #d4ded3;
      display: inline-block;
      position: absolute;
      left: -5px;
      top: 50%;
    }
    :first-child {
      padding: 10px 15px 10px 0;
      :after {
        content: none;
      }
    }
  }
  .react-tabs__tab-list {
    border: 0;
    margin-bottom: 12px;
    display: flex;
  }
  .react-tabs__tab--selected {
    border-bottom-color: #292b29;
    color: #292b29;
  }
  ul {
    border: 1px solid #d4ded3;
    list-style-type: none;
  }
  li {
    width: 100%;
  }
`;
CustomTab.displayName = 'CustomTab';

export const ColHeading = tw.div`
  align-top
`;
ColHeading.displayName = 'ColHeading';

export const ColHeadList = tw.div`
  p-4
  h-70
  flex
  items-center
  ${(p: { $line?: string }) => (p.$line === 'odd' ? 'bg-lightBrown' : '')}
`;
ColHeadList.displayName = 'ColHeadList';

export const ColData = tw.div`
  flex
  items-center
  h-70
  p-4
  ${(p: { $line?: string }) => (p.$line === 'odd' ? 'bg-lightBrown' : '')}
`;
ColData.displayName = 'ColData';

export const Heading = tw(Paragraph)`
  font-normal
  opacity-70
`;
Heading.displayName = 'Heading';

export const Label = tw(Paragraph)`
  font-medium
`;
Label.displayName = 'DesktopLabel';

export const MobileLabel = tw(Paragraph)`
  font-medium
`;
MobileLabel.displayName = 'MobileLabel';

export const MainTitle = tw(Title)`
  text-center
  mb-9
  md:mb-10
`;
MainTitle.displayName = 'MainTitle';

export const SubTitle = tw(Paragraph)`
  text-center
  opacity-70
  mb-2.5
  md:mb-5
`;
SubTitle.displayName = 'SubTitle';

export const Note = tw(Paragraph)`
  relative
  opacity-70
  -top-3
  md:mb-4
`;
Note.displayName = 'Note';

export const Disclaimer = tw(Paragraph)`
  py-5
`;
Disclaimer.displayName = 'Disclaimer';

export const CertificationsList = tw.div`
  flex
  flex-wrap
  mt-10
  mb-10
  gap-x-4
  gap-y-4
`;

export const CertificationListItem = tw.div`
  flex
  flex-col
  items-center
  max-w-150
`;

export const CertificationContainer = tw.div`
  w-150
  h-90
  pl-5
  pr-5
  relative
  flex
  justify-center
  items-center
`;
